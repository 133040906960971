@import '../../colors.css';

.route {
  composes: route from '../../common.css';
}

.main {
  max-width: 50em;
  margin: 0 auto;
  padding: 0 1.5em 1em;
  color: var(--dark-gray-color);
  border-top: 1px transparent solid;
  border-radius: 1em;
  background: var(--almost-white-color);
}
