@import '../../colors.css';

.sectionTitle {
  display: inline-block;
  padding: 0.5em;
  text-decoration: none;
  color: var(--white-color);
  border-radius: 0.75em / 0.75em;
  background: var(--orange-color);
  line-height: 100%;
}

.sectionGrid {
  display: grid;
  /** this needs to be declared here so that the IDE knows about it,
   but the value is set in JS */
  --row-count-two-columns: '';
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(var(--row-count-two-columns), auto);
  grid-gap: 1.5em;
  grid-auto-flow: column;
}

.hasMoreItems {
  min-height: 100vh;
}

.end {
  border-top: 1px solid var(--dark-gray-color);
  margin-top: 1.5em;
  padding-top: 1.25em;
  /* I guess we'll add something here */
}

.end a {
  text-decoration: none;
  color: var(--orange-color);
}

.secondLetterSection a {
  text-decoration: none;
  color: var(--dark-gray-color);
}

.secondLetterSection a:hover {
  color: var(--darker-gray-color);
}

@media screen and (max-width: 35em) {
  .sectionGrid {
    grid-template-columns: 1fr;
    grid-template-rows: initial;
    grid-auto-flow: row;
  }
}

.warning {
  font-weight: bold;
}

.icon {
  width: 1em;
  height: 1em;
  fill: var(--white-color);
}
