.branding {
}

.titleContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  display: block;
  font-size: 2em;
  font-family: 'Ubuntu Condensed', sans-serif;
  font-weight: lighter;
  text-align: center;
  text-decoration: none;
  color: var(--white-color);
  margin-top: 0.5em;
}

.branding a:hover {
  text-decoration: underline;
}

.logoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10em;
  height: 10em;
  margin: 2em auto;
  border-radius: 50%;
  background: var(--white-color);
}

.logoContainer img {
  /* The dimensions need to be set here precisely so that Lighthouse is satisfied */
  /* width: 6em; */
  height: 6em;
}
