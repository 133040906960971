@import '../../colors.css';

.route {
  composes: route from '../../common.css';
}

.main {
  max-width: 50em;
  margin: 0 auto;
  color: var(--white-color);
  border-top: 1px transparent solid;
  border-radius: 1em;
  background: var(--dark-blue-color);
}

.warning {
  margin: 0;
  padding: 1em;
  font-size: 1.5em;
  font-weight: bold;
  text-align: center;
}