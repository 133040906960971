.languageGrid {
  composes: languageGrid from '../term.css';
}

.termSingularText {
  text-transform: uppercase;
  composes: termSingularText from '../term.css';
}

.termPluralText {
  composes: termPluralText from '../term.css';
}

.languageSection {
  composes: languageSection from '../term.css';
}

.polishSection {
  composes: polishSection from '../term.css';
}

.englishSection {
  composes: englishSection from '../term.css';
}

.definition {
  composes: definition from '../term.css';
}

.adminSection {
  padding: 0.125em 1em;
}

.adminSection :is(a, button) {
  font-size: inherit;
  margin-right: 1em;
  padding: 0.5em;
  cursor: pointer;
  text-decoration: none;
  color: var(--dark-gray-color);
  border: none;
  border-radius: 0.75em / 0.75em;
  background: var(--white-color);
}

.adminSection :is(a, button):hover {
  background: var(--light-gray-color);
}

.warning {
  composes: warning from '../term.css';
}