@import './colors.css';

*, *::after, *::before {
  box-sizing: inherit;
  word-break: break-word;
}

body {
  box-sizing: border-box;
  margin: 0;
  /* we need to define the background color for body specifically somewhere, not for its bodies,
   because browsers treat it specifically in many ways, for instance on Microsoft Edge
   when using ztouchpad you can kind of scroll out of the page for a brief moment until you release
   your fingers, and if you don't specify a background color for the body element,
   that extra space would be white, and it looks very bad.
 */
  background: #D0E8F2 url('/src/img/background.svg') repeat-y;
  background-size: 100% 150em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input, body {
  font-family: Ubuntu, sans-serif;
}
