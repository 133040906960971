.languageGrid {
  display: grid;
  padding: 0 1.5em 1em;
  grid-template-columns: min-content auto;
}

.termSingularText {
  font-size: 1.25em;
  font-weight: bold;
  margin: 0.25em 0;
}

.termPluralText {
  font-size: 1.25em;
  margin: 0.25em 0;
}

.languageSection {
  display: grid;
  margin-top: 0.75em;
}

.polishSection {
  composes: languageSection;
  grid-template-columns: 1fr;
}

.polishSection a {
  text-decoration: none;
  color: var(--white-color);
}

.polishSection a:hover {
  text-decoration: underline;
}

.englishSection {
  composes: languageSection;
  grid-template-columns: 1fr 1fr;
}

.languageGrid img {
  margin: 1em 1em 1em 0;
}

.definition {
  padding: 2em 1.5em;
  color: var(--black-color);
  border-radius: 1em;
  background: var(--white-color);
}

.warning {
  font-weight: bold;
  padding-bottom: 0.5em;
}

@media screen and (max-width: 35em) {
  .englishSection {
    grid-template-columns: 1fr;
  }
}
