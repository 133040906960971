@import '../../colors.css';

.queryForm {
  position: relative;
  max-width: 25em;
  margin: 0 auto;
}

.queryInput {
  font-size: 1rem;
  /* setting position explicitly is required for z-index to work */
  position: inherit;
  /* so that the box-shadow from .suggestions doesn't cover this */
  z-index: 1;
  display: block;
  width: 100%;
  padding: 1em 6.5em 1em 1.5em;
  border: none;
  border-radius: 1.5em;
  background: var(--almost-white-color);
}

.queryInput.queryInputExpanded {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.queryInput:focus {
  outline: 0.125em solid var(--orange-color);
}

.queryInput::selection {
  background: var(--blue-color);
}

.queryForm button {
  font-size: 1rem;
  width: 3em;
  cursor: pointer;
  border: none;
  background: none;
}

.clearButton {
  position: absolute;
  z-index: 1; /* see .queryInput */
  top: 0;
  right: 3em;
  bottom: 0;
  vertical-align: middle;
}

.clearButton .icon {
  fill: var(--orange-color);
}

.icon {
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  fill: var(--dark-blue-color);
}

.searchButton {
  position: absolute;
  z-index: 1; /* see .queryInput */
  top: 0;
  right: 0;
  bottom: 0;
  vertical-align: middle;
  border-top-right-radius: 1.5em;
  border-bottom-right-radius: 1.5em;
}

.searchButton img {
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
}

.searchButton::before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 0.125em;
  height: 2em;
  margin: auto;
  content: '';
  background: var(--blue-color);
}

.searchButton.runButtonExpanded {
  border-bottom-right-radius: 0;
}

.suggestions {
  position: absolute;
  visibility: hidden;
  width: 100%;
  padding-top: 0.25em;
  border-top: 0.125em solid var(--orange-color);
  border-bottom-right-radius: 1.5em;
  border-bottom-left-radius: 1.5em;
  background: var(--almost-white-color);
  box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.35);
}

.suggestions.suggestionsExpanded {
  visibility: visible;
}

.suggestions button {
  font: inherit;
  position: relative;
  display: inherit;
  width: 100%;
  padding: .5em 3em .5em 1.5em;
  cursor: pointer;
  text-align: start;
  border: inherit;
  background: inherit;
}

.suggestions button:last-child {
  padding-bottom: 0.75em;
  border-bottom-right-radius: 1.5em;
  border-bottom-left-radius: 1.5em;
}

.suggestions .hovered {
  background: var(--orange-color);
}

.suggestions button::after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3em;
  margin: auto;
  content: "\f02d"; /* book */
  vertical-align: middle;
  color: var(--dark-blue-color);
}

.suggestions button.commandSuggestion::after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f0ad"; /* wrench */
  vertical-align: middle;
  color: var(--dark-blue-color);
}

.warning {
  color: var(--white-color);
  text-align: center;
  padding-top: 0.5em;
  font-weight: bold;
}
