@import '../../../colors.css';

.route {
  composes: route from '../../../common.css';
}

.title {
  composes: title from '../info.css';
}

.main {
  composes: main from '../info.css';
}

.authorsImg {
	width: 100%;
	border-radius: 1em;
	margin-bottom: 0.5em;
}
