@import '../../colors.css';

.searchContainer {
  margin: auto;
  padding: 0 0.5em;
}

.letters {
  font-size: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 26em;
  padding: 0 0.5em;
  text-align: center;
  margin: 1em auto;
}

.letters a:hover {
  color: var(--light-gray-color);
}

.letters .letter {
  font-weight: bold;
  margin: 0 0.25em;
  padding: 0 0.375em;
  vertical-align: center;
  text-decoration: none;
  color: var(--white-color);
}

.activeLetter {
  border-radius: 0.375em;
  background: var(--orange-color);
}

.warning {
  color: var(--white-color);
  text-align: center;
  padding: 1em;
  font-weight: bold;
}